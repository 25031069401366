/* -------- owl coroucel css ------------*/
.item img{
    display: block;
    width: 100%;
    height: auto;
}

.owl-dots {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    text-align: center;
}

.owl-dot {
    display: inline-block;
    zoom: 1;
    display: inline;
}

.owl-dot span {
    width: 25px;
    height: 3px;
    margin: 5px 7px;
    background: #a3a1a1d4;
    display: block;
    /*-webkit-backface-visibility: visible;*/
    transition: opacity 200ms ease;
    
}

.owl-dot.active span,
.owl-dot:hover span {
    background: rgb(34, 33, 33);
}


/*   -------FULL WİDTH--------*/

.fw .owl-nav {
    border: solid 0px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.144);
    display: flex;
    justify-content: center;
    align-items: center;
}

.fw .owl-nav button.owl-prev {
    position: absolute;
    left: 30px;
}

.fw .owl-nav button.owl-next {
    position: absolute;
    right: 30px;
}

.fw .owl-nav button.owl-next span,
.fw .owl-nav button.owl-prev span {
  
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    font-size: 35px;
    border-radius: 50%;
    text-align: center;
    padding-bottom: 2px;
    background: rgba(255, 255, 255, 0.726);
    color: rgb(150, 145, 145);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.fw .owl-nav button.owl-next span:hover,
.fw .owl-nav button.owl-prev span:hover {
    background: rgba(255, 255, 255, 0.979);
    color: rgb(26, 24, 24);

}

.fw .owl-nav button:hover, .fw .owl-nav button:focus, .fw .owl-nav button:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: 0;
    box-shadow: 0;
}

/*   -------m-slider-thump--------*/


.m-slider-thump {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.m-slider-thump .item{
    margin: 0px;
}



.m-slider-thump .owl-nav {
    border: solid 0px;
}

.m-slider-thump .owl-nav button.owl-prev {
    position: absolute;
    left: 10px;
    top: 45%;
    top: calc(50% - 25px);
    
}



.m-slider-thump .owl-nav button.owl-next {
    position: absolute;
    right: 10px;
    top: calc(50% - 25px);
}


.m-slider-thump .owl-nav button.owl-next span,
.m-slider-thump .owl-nav button.owl-prev span {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.726);
    color: rgb(150, 145, 145);
    width: 40px;
    height: 40px;
    font-size: 36px;
    padding-bottom:2px; 
    border-radius: 50rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    
}

.m-slider-thump .owl-nav button.owl-next span:hover,
.m-slider-thump .owl-nav button.owl-prev span:hover {
    background-color: rgba(255, 255, 255, 0.979);
    color: rgb(26, 24, 24);

}

.m-slider-thump .owl-nav button:hover, .fw .owl-nav button:focus, .fw .owl-nav button:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: 0;
    box-shadow: 0;
}



/*   -------OWL VİTRİN--------*/

.vt {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.vt .owl-nav {
    border: solid 0px;
}

.vt .owl-nav button.owl-prev {
    position: absolute;
    right: 50px;
    top: -60px;
    
}

.vt .owl-nav button.owl-next {
    position: absolute;
    right: 10px;
    top: -60px;
}


.vt .owl-nav button.owl-next span,
.vt .owl-nav button.owl-prev span {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 35px;
    height: 35px;
    font-size: 25px;
    padding-bottom:2px; 
    border-radius: 3px;
    background-color: #fe696a;
    border-color: #fe696a;
    color: #fff;
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgb(254 105 106 / 90%);

}

.vt .owl-nav button.owl-next span:hover,
.vt .owl-nav button.owl-prev span:hover {
    border-color: #fe3638;
    background-color: #fe3638;
    color: #fff;
    box-shadow: none;

}

.vt .owl-nav button:hover, .fw .owl-nav button:focus, .fw .owl-nav button:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: 0;
    box-shadow: 0;
}


/*   -------OWL HABERLER--------*/


.vthaber  {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.vthaber  .owl-nav {
    border: solid 0px;
}

.vthaber  .owl-nav button.owl-prev {
    position: absolute;
    right: 50px;
    top: -60px;
    
}

.vthaber  .owl-nav button.owl-next {
    position: absolute;
    right: 10px;
    top: -60px;
}


.vthaber  .owl-nav button.owl-next span,
.vthaber  .owl-nav button.owl-prev span {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 35px;
    height: 35px;
    font-size: 25px;
    padding-bottom:2px; 
    border-radius: 3px;
    background-color: #fe696a;
    border-color: #fe696a;
    color: #fff;
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgb(254 105 106 / 90%);

}

.vthaber  .owl-nav button.owl-next span:hover,
.vthaber  .owl-nav button.owl-prev span:hover {
    border-color: #fe3638;
    background-color: #fe3638;
    color: #fff;
    box-shadow: none;

}

.vthaber  .owl-nav button:hover, .fw .owl-nav button:focus, .fw .owl-nav button:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: 0;
    box-shadow: 0;
}



/*   -------Vitrin Group--------*/

.vtgroup .item {
    text-align: center;
}

.vtgroup span {
    text-align: center;
}

.vtgroup a {
    text-decoration: none;
    color: rgb(0, 0, 0);

}


.pro-img {
    border: solid 5px #ddd;
    border-radius: 50rem;
}

.pro-img img {
    border-radius: 50rem;
}


.pro-img img:hover {
    transform: rotate(-360deg);
    transition: all 0.5s ease 0s;
    -webkit-transform: rotate(-360deg);
    -webkit-transition: all 0.5s ease 0s;
    cursor: pointer;
}

.vtgroup {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.vtgroup .owl-nav {
    border: solid 0px;
}

.vtgroup .owl-nav button.owl-prev {
    position: absolute;
    right: 50px;
    top: -60px;
    
}

.vtgroup .owl-nav button.owl-next {
    position: absolute;
    right: 10px;
    top: -60px;
}


.vtgroup .owl-nav button.owl-next span,
.vtgroup .owl-nav button.owl-prev span {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 35px;
    height: 35px;
    font-size: 25px;
    padding-bottom:2px; 
    border-radius: 3px;
    background-color: #fe696a;
    border-color: #fe696a;
    color: #fff;
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgb(254 105 106 / 90%);

}

.vtgroup .owl-nav button.owl-next span:hover,
.vtgroup .owl-nav button.owl-prev span:hover {
    border-color: #fe3638;
    background-color: #fe3638;
    color: #fff;
    box-shadow: none;

}

.vtgroup .owl-nav button:hover, .fw .owl-nav button:focus, .fw .owl-nav button:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: 0;
    box-shadow: 0;
}
