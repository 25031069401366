.m-product {
  font-size: inherit;
}

.m-product-image {
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 275px;
}


.m-product-image a {
    text-decoration: none;
    
}

.m-product-image a img.pic-1,
.m-product-image a img.pic-2 {
    width: 100%;
    height: 100%;
}

.m-product-image a img.pic-2  {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.m-product:hover .m-product-image a img.pic-2  {
    opacity: 1;
}

ul.social {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 15%;
    left: 20px;
    z-index: 1;
    
}

ul.social li {
    margin: 0 0 10px;
    display: block;
    transform: translateX(-100px);
    transition: all .3s
}

.m-product:hover .social li {
    transform: translateX(0);
   
}

ul.social li a {
    color: #5e5c5c;
    background-color: rgb(255 255 255);
    font-size: 15px;
    line-height: 45px;
    text-align: center;
    height: 40px;
    width: 40px;
    border-radius: 5px;
    display: block;
    transition: all .3s ease 0s
}

ul.social li a:hover {
    color: #e91111;
}


.m-product .add-to-cart {
    position: absolute;
    left: 0;
    bottom: -100%;
    display: block;
    width: 100%;
    padding: 5px;
    text-align: center;
    transition: all .3s
}

.m-product:hover .add-to-cart {
    bottom: 0px;
}

.m-product .product-new-label {
    background-color: #3498db;
    color: #fff;
    font-size: 17px;
    padding: 5px 10px;
    position: absolute;
    left: 0;
    top: 0;
    transition: all .3s
}

.m-product:hover .product-new-label {
    opacity: 0
}

.m-product .product-content {
    padding: 5px;
    text-align: center;
}

.m-product .title {
    color: #000;
    height: 80px;
    overflow: auto;
    padding: 5px;
    text-align: left;
}

.m-product .title a {
    color: #303030;
    font-size: .95rem;
}

.m-product .title a:hover {
    color: #303030
}



/* Scroll Box  */

.scrol-box{
    max-height: 250px;
    overflow-y: scroll;
  }
  
  .scrol-box::-webkit-scrollbar {
    width: 0.2rem;
  }
  
  .scrol-box::-webkit-scrollbar-button {
    background: #5a5959;
    height: 0.2rem;
    
  }
  
  .scrol-box::-webkit-scrollbar-thumb {
    background: #c1c1c1;
  }
  
  .scrol-box::-webkit-scrollbar-track-piece {
    background: rgb(245, 243, 243);
  }

  
@media screen and (max-width:990px) {
    .m-product {
        margin-bottom: 30px
    }

}