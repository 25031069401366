.mm-slideout { z-index:auto;}

.logo img {
    max-width: 100%;
    max-height: 60px;
  }


.bigmenus{
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.bigmenus li a{
    border: solid 1px #ddd;
    display: flex;
    text-decoration: none;
    color: rgb(94, 90, 90);
    padding:15px 0;
    opacity: 1;
    transition: 0.3s;
}

.bigmenus li a:hover{
    opacity: none;
}

.bigmenus span{
    width: calc(100% - 60px);
    padding: 5px 0 0 10px;
    
}

.bigmenus i{
    border-right: solid 1px #ddd;	
    width: 60px;
    font-size: 30px;
    padding:10px 0;
    text-align: center;
}



.SiteFooter {
    width: 100%;
   
    background: #17202a;
    color: #fff;
}

.Site-Copyright {
    width: 100%;
    color: #fff;
    background: #17202a;
    border-top: solid 1px #2c3e50 !important;
}

#odeme-imges {
    width: 100%;
    height: 18px;
}

.SiteFooter ul {
    margin: 0;
    padding: 0;
}

.SiteFooter ul li {
    width: 100%;
    padding: 5px;
    list-style: none;
}

.SiteFooter ul li a {
    text-decoration: none;
    color: #fff;
    
}

.SiteFooter h6 {
    border-bottom: 1px solid #fff;
    padding: 5px;
    margin: 5px;
    color: #fff;
}