html,
body {
    padding: 0;
    margin : 0;
    height : 100%;
}

body {
    font-family             : 'Rubik', sans-serif;
    -webkit-text-size-adjust: none;
    font-size               : 0.925rem;
    color                   : #2C2C2C;
    position                : relative;
}

a {

    text-decoration: none;
}

.modal-backdrop {
    display: none;
}

.modal {
    background: rgba(0, 0, 0, 0.5);
}

.wf-500 {
    font-weight: 500;
}

.wf-600 {
    font-weight: 600;
}



.wpp {
    position       : fixed !important;
    bottom         : 10px;
    right          : 10px;
    width          : 45px;
    height         : 45px;
    border-radius  : 50rem;
    z-index        : 999999999;
    background     : #46c055;
    color          : #ffffff;
    display        : flex;
    justify-content: center;
    align-items    : center;

    font-size: 25px;
}

.wpp:hover {
    background: #53df63;
    color     : #ffffff;
}

.micro-header+* {
    margin-top: -4.875rem;
}

.micro-header {
    padding-bottom: 5rem;
}

.tema-bg {
    background-color: rgba(2, 4, 29, 0.856) !important;
}

.m-hoverbox {
    position  : absolute;
    left      : 0px;
    top       : 0px;
    width     : 100%;
    height    : 100%;
    padding   : 15px;
    background: rgba(235, 235, 235, 0.459);
    display   : none;
}


.m-hover:hover .m-hoverbox {
    display    : flex;
    align-items: center;
}


.dropdown-menu {
    padding: 5px !important;
}



.form-control:focus,
.form-select:focus {
    outline           : 0 !important;
    outline-offset    : 0 !important;
    background-image  : none !important;
    -webkit-box-shadow: none !important;
    box-shadow        : none !important;

}


.input-group-text {
    z-index   : 999;
    position  : absolute;
    left      : 1px;
    top       : 2px;
    padding   : 0.73rem;
    background: transparent;
    border    : none;

}


.input-group-text span {
    padding-left: 35px;
}



button:focus,
.btn:focus,
a.btn:focus {
    outline           : 0 !important;
    outline-offset    : 0 !important;
    background-image  : none !important;
    -webkit-box-shadow: none !important;
    box-shadow        : none !important;
}

.text-mavi {
    color: #4e54c8 !important;
}

.ciz {
    text-decoration: line-through;
}


.modal-header {
    border-bottom: 0;
}

.card-footer {

    background-color: transparent;
    border-top      : 0;
}

.hover-shadow:hover {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    border    : 0;
}




/*---------Buton Custom----------*/

.btn {
    padding: 0.625rem 1.375rem;
}



.btn-icon {
    padding: 0;
    width  : 2.90rem;
    height : 2.90rem;
}

.btn-icon i {
    font-size      : 15px;
    min-height     : 100%;
    min-width      : 100%;
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.btn-icon-xs {
    padding: 0;
    width  : 2rem;
    height : 2rem;
}

.btn-icon-xs i {
    font-size      : 15px;
    min-height     : 100%;
    min-width      : 100%;
    display        : flex;
    justify-content: center;
    align-items    : center;
}



.btn-icon-xxs {
    padding: 0;
    width  : 1.5rem;
    height : 1.5rem;
}

.btn-icon-xxs i {
    font-size      : 12px;
    min-height     : 100%;
    min-width      : 100%;
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.btn-icon-sm {
    padding: 0;
    width  : 2.75rem;
    height : 2.75rem;
}

.btn-icon-sm i {
    font-size: 16px;
}


.btn-icon-lg {
    padding: 0;
    width  : 3.25rem;
    height : 3.25rem;
}

.btn-icon-lg i {
    font-size      : 24px;
    min-height     : 100%;
    min-width      : 100%;
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.btn-circle {
    border-radius: 50rem !important;
}






.btn-facebook {
    background: rgb(59, 89, 152);
    color     : #ffffff;
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgb(59, 89, 152);
}

.btn-facebook:hover {
    background: #324f8f;
    color     : #ffffff;
    box-shadow: none;
}

.btn-twitter {
    background: rgb(28, 160, 242);
    color     : #ffffff;
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgb(28, 160, 242);
}

.btn-twitter:hover {
    background: rgb(21, 139, 212);
    color     : #ffffff;
    box-shadow: none;
}

.btn-instagram {
    background: rgb(245, 96, 64);
    color     : #ffffff;
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgb(245, 96, 64);
}

.btn-instagram:hover {
    background: rgb(238, 87, 53);
    color     : #ffffff;
    box-shadow: none;
}

.btn-pinterest {
    background: rgb(223, 0, 34);
    color     : #ffffff;
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgb(223, 0, 34);
}

.btn-pinterest:hover {
    background: rgb(187, 1, 29);
    color     : #ffffff;
    box-shadow: none;
}

.btn-beyaz {
    background: rgb(255, 255, 255);
    color     : #000000;
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgb(17, 17, 17);
}

.btn-beyaz:hover {
    background: rgb(245, 242, 242);
    box-shadow: none;
}


.btn-fav {
    background: rgb(241, 238, 238);
    color     : #000000;
}









.btn-yesil {
    background-color: #42d697;
    border-color    : #42d697;
    color           : #fff;
}

.btn-yesil.btn-shadow {
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgb(66 214 151 / 90%);
}

.btn-yesil:hover {
    border-color    : #42d697;
    background-color: #42d697;
    color           : #fff;
    box-shadow      : none;
}


.btn-kirmizi {
    background-color: #fe696a;
    border-color    : #fe696a;
    color           : #fff;

}

.btn-kirmizi.btn-shadow {
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgb(254 105 106 / 90%);
}

.btn-kirmizi:hover {
    border-color    : #fe3638;
    background-color: #fe3638;
    color           : #fff;
    box-shadow      : none;
}

.btn-mavi {
    background-color: #696bfe;
    border-color    : #7f69fe;
    color           : #fff;

}

.btn-mavi.btn-shadow {
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(105, 115, 254, 0.9);
}

.btn-mavi:hover {
    border-color    : #4a36fe;
    background-color: #5436fe;
    color           : #fff;
    box-shadow      : none;
}





.icon-xlg {
    font-size: 50px;
}

.icon-lg {
    font-size: 40px;
}

.icon-m {
    font-size: 30px;
}

.icon-s {
    font-size: 20px;
}



.btn-link {

    text-decoration: none;
}

/*---------FORM-RESET----------*/



.form-control {
    font-size: 0.9375rem;
    padding  : 0.625rem 1rem;
    color    : #4b566b;
}

.form-select {
    font-size: 0.9375rem;
    padding  : 0.625rem 3rem 0.625rem 1rem;
    color    : #4b566b;
}



/*********CARD*************/

.card-header {
    padding: 1rem;
}

.do {
    display        : flex;
    justify-content: center;
}

.form-check-btn {
    width: 100%;

}

.form-check-btn label:hover {
    color: rgb(7, 82, 153);

}



.form-check-btn label {
    display: flex;
    width  : 100%;
    padding: 5px 0;
}

.form-check-btn span {
    width: calc(100% - 23px);


}

.form-check-btn input[type='checkbox'] {
    height      : 18px;
    width       : 18px;
    margin-right: 5px;

}



.accordion-button {
    border: none;

}

.accordion-button:focus {
    border-color: none;
    box-shadow  : none;
}


.accordion-button:not(.collapsed) {
    color           : #000;
    background-color: #ffffff;
    box-shadow      : none;
}




.m-nav-tabs.nav-tabs .nav-link {
    color        : #2b2b2b;
    padding      : .75rem 1rem;
    border-radius: 0;
    border       : 0;
    border-bottom: solid 2px transparent;
}



.m-nav-tabs.nav-tabs .nav-link.active {
    color       : #0a196d;
    border-color: rgb(39, 41, 158);

}



.vertical-menu {
    width: 100%;
}

.vertical-menu a {
    background-color: rgb(255, 255, 255);
    color           : black;
    display         : block;
    padding         : 12px;
    text-decoration : none;
}

.vertical-menu a.active,
.vertical-menu a:hover {
    color: brown;
}



@media (min-width: 500px) {

    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 100%;
    }
}

@media (min-width: 768px) {

    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 100%;
    }
}

@media (min-width: 992px) {

    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 100%;
    }

}

@media (min-width: 1280px) {

    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1260px;
    }
}



/* The Loader */
#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999999999;
    overflow: hidden;
  }
  .no-js #loader-wrapper {
    display: none;
  }
  
  #loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 100px;
    height: 100px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #16a085;
    -webkit-animation: spin 1.7s linear infinite;
            animation: spin 1.7s linear infinite;
    z-index: 11;
  }
  #loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #e74c3c;
    -webkit-animation: spin-reverse .6s linear infinite;
            animation: spin-reverse .6s linear infinite;
  }
  #loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #f9c922;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin-reverse {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(-360deg);
    }
  }
  @keyframes spin-reverse {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
  #loader-wrapper .loader-section {
    position: fixed;
    top: 0;
    width: 51%;
    height: 100%;
    background: #FFF;
    z-index: 10;
  }
  
  #loader-wrapper .loader-section.section-left {
    left: 0;
  }
  
  #loader-wrapper .loader-section.section-right {
    right: 0;
  }
  
  /* Loaded styles */
  .loaded #loader-wrapper .loader-section.section-left {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  
  .loaded #loader-wrapper .loader-section.section-right {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  
  .loaded #loader {
    opacity: 0;
    transition: all 0.3s ease-out;
  }
  
  .loaded #loader-wrapper {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    transition: all 0.3s 1s ease-out;
  }