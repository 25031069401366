// Fonts
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');


@import '~bootstrap/scss/bootstrap';

@import '~bootstrap-icons/font/bootstrap-icons';

@import '~owl.carousel';

@import '~@fancyapps/fancybox';


@import 'variables';

@import 'inc';

@import 'header';

@import 'uruncard';

@import 'owlslider';

