ul.ts-megamenu {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 50px;
  }

  ul.ts-megamenu>li {
    float: left;
    height: 100%;
  }


  ul.ts-megamenu>li>a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    height: 100%;
    color: rgb(29, 0, 107);
  }

  ul.ts-megamenu>li>a:hover {
    color: rgb(218, 19, 95)
  }


  /* MENU Ara Kategori */

  ul.ts-megamenu li ul {
    position: absolute;
    top: 100%;
    left: 0;
    margin: 0;
    padding: 0px;
    list-style: none;
    background: #fff;
    width: 100%;
    z-index: 9999;
    border: solid 1px rgb(243, 243, 243);
    border-radius: 0 0 5px 5px;
    display: none;
  }

  ul.ts-megamenu li ul li{
   text-align: center;
  }

  ul.ts-megamenu li ul li a{
    color: rgb(29, 0, 107);
    font-weight: 500;
  }

  ul.ts-megamenu li ul li a:hover{
    color: rgb(218, 19, 95)
  }

  ul.ts-megamenu li ul li a img{
    width: 100px;
    height: 80px;
    margin-bottom: 5px;
  }

  /* MENU HOVER*/

  ul.ts-megamenu>li:hover>ul {
    display: flex;
  }